"use client";

import * as React from "react";
import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import { IconChevronDown } from "@web-monorepo/dds-icons";
import styles from "./DDSNavigationMenu.module.css";
import {
  iconButton,
  small as iconButtonSize,
  gray as iconButtonColor,
} from "../DDSIconButton/DDSIconButton.module.css";

const DDSNavigationMenu = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Root ref={ref} className={`${styles.root} ${className ?? ""}`} {...props}>
    {children}
    <DDSNavigationMenuViewport />
  </NavigationMenuPrimitive.Root>
));
DDSNavigationMenu.displayName = "DDSNavigationMenu";

const DDSNavigationMenuList = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.List ref={ref} className={`${styles.list} ${className ?? ""}`} {...props} />
));
DDSNavigationMenuList.displayName = "DDSNavigationMenuList";

const DDSNavigationMenuItem = NavigationMenuPrimitive.Item;

type DDSNavigationMenuTriggerProps = React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger> & {
  ["data-name"]: string;
  /** For NavigationmenuTrigger elements, aria-label is required for accessibility reasons */
  ["aria-label"]: string;
  showChevron?: boolean;
};

const DDSNavigationMenuTrigger = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  DDSNavigationMenuTriggerProps
>(({ className, children, showChevron = false, ...props }, ref) => (
  <NavigationMenuPrimitive.Trigger
    ref={ref}
    className={`${styles.trigger} ${iconButton} ${iconButtonSize} ${iconButtonColor} ${className ?? ""}`}
    {...props}
    onPointerMove={(event) => event.preventDefault()}
    onPointerLeave={(event) => event.preventDefault()}
  >
    {children}
    {showChevron && <IconChevronDown className={styles.chevronIcon} aria-hidden="true" />}
  </NavigationMenuPrimitive.Trigger>
));
DDSNavigationMenuTrigger.displayName = "DDSNavigationMenuTrigger";

const DDSNavigationMenuContent = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Content
    onPointerEnter={(event) => event.preventDefault()}
    onPointerLeave={(event) => event.preventDefault()}
    ref={ref}
    className={`${styles.content} ${className ?? ""}`}
    {...props}
  />
));
DDSNavigationMenuContent.displayName = "DDSNavigationMenuContent";
const DDSNavigationMenuLink = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Link>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Link>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Link ref={ref} className={`${styles.link} ${className ?? ""}`} {...props} />
));
DDSNavigationMenuLink.displayName = "DDSNavigationMenuLink";

const DDSNavigationMenuViewport = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Viewport>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Viewport>
>(({ className, ...props }, ref) => (
  <div className={styles.viewport}>
    <NavigationMenuPrimitive.Viewport className={`${styles.viewportContent} ${className ?? ""}`} ref={ref} {...props} />
  </div>
));
DDSNavigationMenuViewport.displayName = "DDSNavigationMenuViewport";

const DDSNavigationMenuIndicator = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Indicator ref={ref} className={`${styles.indicator} ${className ?? ""}`} {...props}>
    <div className={styles.indicatorArrow} />
  </NavigationMenuPrimitive.Indicator>
));
DDSNavigationMenuIndicator.displayName = "DDSNavigationMenuIndicator";

const DDSNavigationMenuSub = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Sub>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Sub>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Sub ref={ref} className={`${styles.sub} ${className ?? ""}`} {...props} />
));
DDSNavigationMenuSub.displayName = "DDSNavgitationMenuSub";

export {
  DDSNavigationMenu,
  DDSNavigationMenuList,
  DDSNavigationMenuItem,
  DDSNavigationMenuContent,
  DDSNavigationMenuTrigger,
  DDSNavigationMenuLink,
  DDSNavigationMenuSub,
  DDSNavigationMenuIndicator,
  DDSNavigationMenuViewport,
};
