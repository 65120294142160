import * as React from "react";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

/**
 * A scrollable list group (named after Bootstrap ListGroup)
 *
 * Handles hover and selected states. Handles scrolling when content overflows
 * container.
 */

type ListItem = {
  node: React.ReactNode;
  isSelected?: boolean;
  key?: string;
};

type ListGroupProps = {
  items: ListItem[];
  className?: string;
};

const ListGroup = ({ items, className }: ListGroupProps) => {
  return (
    <ul sx={styles.container} className={className}>
      {items.map((item, i) => {
        const { isSelected, node } = item;

        return (
          <li
            key={item.key || i}
            sx={{
              "&:hover": styles.hovered,
              "&.selected": styles.selected,
            }}
            className={isSelected ? "selected" : ""}
            aria-label="List group list item"
          >
            {node}
          </li>
        );
      })}
    </ul>
  );
};

export default ListGroup;

const styles: Record<string, ThemeUIStyleObject> = {
  container: {
    border: "dt_card",
    borderRadius: "dt_radius_s",
    backgroundColor: "dt_background_primary",
    listStyle: "none",
    padding: 0,
    margin: 0,
    "li:first-of-type": {
      borderTopLeftRadius: "dt_radius_s_inset",
      borderTopRightRadius: "dt_radius_s_inset",
    },
    "li:last-child": {
      borderBottomLeftRadius: "dt_radius_s_inset",
      borderBottomRightRadius: "dt_radius_s_inset",
    },
  },
  selected: {
    // boxShadow: RAW_cssValue(`inset 0 0 0 1px ${theme.colors.dt_content_accent}`),
    border: "dt_active",
    backgroundColor: "dt_background_accent",
  },
  hovered: {
    backgroundColor: "dt_background_accent",
  },
};
