import { ReactNode } from "react";
import { ThemeUIStyleObject } from "../../stylingLib";

// eslint-disable-next-line react-refresh/only-export-components
export const badgeSizes = ["xxs", "xs", "s", "m", "l", "xl"] as const;
export type BadgeSizesType = (typeof badgeSizes)[number];
export type BadgeKinds = keyof typeof badgeColors;

type PropsWithChildren<T> = Omit<T, "children"> & { children?: ReactNode };

export type BadgeProps = {
  role?: string;
  size?: BadgeSizesType;
  kind?: BadgeKinds;
  noBorder?: boolean;
  className?: string;
  outline?: boolean;
  /**
   * For organization purposes, please do not use children on the badge.
   * If you need to add a number, use the NumberBadge component,
   * if you need to add an icon, use the IconBadge component
   */
  children?: never;
};

export const Badge = ({
  kind = "success",
  size = "m",
  noBorder,
  outline = false,
  ...rest
}: PropsWithChildren<BadgeProps>): JSX.Element => {
  const border: ThemeUIStyleObject = noBorder ? { border: "none" } : {};
  return (
    <div
      sx={{
        ...defaultStyles,
        ...sizeStyles[size],
        ...border,
        ...(outline && { borderColor: badgeColors[kind] }),
        ...(outline && { color: badgeColors[kind] }),
        ...(kind === "warning" && { color: "dt_content_primary" }),
        backgroundColor: outline ? "dt_background_primary" : badgeColors[kind],
      }}
      {...rest}
    >
      {rest.children}
    </div>
  );
};

type ComponentLibraryProps = {
  Badge: ({ kind, size, noBorder, ...rest }: PropsWithChildren<BadgeProps>) => JSX.Element;
};

//This ignore was added on porpuse to avoid people adding children to the badge component
// eslint-disable-next-line react-refresh/only-export-components
export const BadgeWithChildren: ComponentLibraryProps = {
  Badge,
};
// eslint-disable-next-line react-refresh/only-export-components
export const badgeColors = {
  danger: "dt_content_danger",
  warning: "dt_content_warning",
  primary: "dt_content_accent",
  secondary: "dt_content_disabled",
  success: "dt_content_success",
} as const;

const defaultStyles: ThemeUIStyleObject = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderColor: "dt_content_light",
  borderStyle: "solid",
  borderRadius: "dt_radius_pill",
  color: "dt_content_light",
  textAlign: "center",
  padding: "dt_xs",
  fontWeight: 600,
  maxWidth: "fit-content",
  maxHeight: "fit-content",
  boxSizing: "unset",
};

const sizeStyles: Record<BadgeSizesType, ThemeUIStyleObject> = {
  xxs: {
    borderWidth: "0px",
    minWidth: "6px",
    minHeight: "6px",
  },
  xs: {
    borderWidth: "0px",
    minWidth: "12px",
    minHeight: "12px",
  },
  s: {
    borderWidth: "3px",
    minWidth: "16px",
    minHeight: "16px",
  },
  m: {
    borderWidth: "3px",
    minWidth: "22px",
    minHeight: "22px",
  },
  l: {
    borderWidth: "3px",
    minWidth: "35px",
    minHeight: "35px",
  },
  xl: {
    borderWidth: "6px",
    minWidth: "60px",
    minHeight: "60px",
  },
};
