import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { TranslatedString } from "@web-monorepo/i18next";

export function getTranslatedLanguageName(language: string | undefined | null): TranslatedString | null {
  if (!language) return null;
  return (
    {
      af: autoTranslate("Afrikaans"),
      "af-ZA": autoTranslate("Afrikaans"),
      am: autoTranslate("Amharic"),
      "am-ET": autoTranslate("Amharic"),
      ar: autoTranslate("Arabic"),
      "ar-AR": autoTranslate("Arabic"),
      be: autoTranslate("Belarusian"),
      "be-BY": autoTranslate("Belarusian"),
      bg: autoTranslate("Bulgarian"),
      "bg-BG": autoTranslate("Bulgarian"),
      bn: autoTranslate("Bengali"),
      "bn-BD": autoTranslate("Bengali"),
      ca: autoTranslate("Catalan"),
      "ca-ES": autoTranslate("Catalan"),
      cs: autoTranslate("Czech"),
      "cs-CZ": autoTranslate("Czech"),
      cy: autoTranslate("Welsh"),
      "cy-GB": autoTranslate("Welsh"),
      da: autoTranslate("Danish"),
      "da-DK": autoTranslate("Danish"),
      de: autoTranslate("German"),
      "de-DE": autoTranslate("German"),
      el: autoTranslate("Greek"),
      "el-GR": autoTranslate("Greek"),
      en: autoTranslate("English"),
      "en-GB": autoTranslate("English (UK)"),
      "en-US": autoTranslate("English (US)"),
      es: autoTranslate("Spanish"),
      "es-ES": autoTranslate("Spanish (Spain)"),
      "es-MX": autoTranslate("Spanish (Latin America)"),
      et: autoTranslate("Estonian"),
      "et-EE": autoTranslate("Estonian"),
      eu: autoTranslate("Basque"),
      "eu-ES": autoTranslate("Basque"),
      fa: autoTranslate("Persian"),
      "fa-IR": autoTranslate("Persian"),
      fi: autoTranslate("Finnish"),
      "fi-FI": autoTranslate("Finnish"),
      fil: autoTranslate("Filipino"),
      "fil-PH": autoTranslate("Filipino (Philippines)"),
      fr: autoTranslate("French"),
      "fr-CA": autoTranslate("French (Canada)"),
      "fr-FR": autoTranslate("French (France)"),
      ga: autoTranslate("Gaelic"),
      "ga-IE": autoTranslate("Gaelic"),
      gl: autoTranslate("Galician"),
      "gl-ES": autoTranslate("Galician"),
      he: autoTranslate("Hebrew"),
      "he-IL": autoTranslate("Hebrew"),
      hi: autoTranslate("Hindi"),
      "hi-IN": autoTranslate("Hindi"),
      hr: autoTranslate("Croatian"),
      "hr-HR": autoTranslate("Croatian"),
      hu: autoTranslate("Hungarian"),
      "hu-HU": autoTranslate("Hungarian"),
      id: autoTranslate("Indonesian"),
      "id-ID": autoTranslate("Indonesian"),
      it: autoTranslate("Italian"),
      "it-IT": autoTranslate("Italian"),
      ja: autoTranslate("Japanese"),
      "ja-JP": autoTranslate("Japanese"),
      kk: autoTranslate("Kazakh"),
      "kk-KZ": autoTranslate("Kazakh"),
      ko: autoTranslate("Korean"),
      "ko-KR": autoTranslate("Korean"),
      lt: autoTranslate("Lithuanian"),
      "lt-LT": autoTranslate("Lithuanian"),
      lv: autoTranslate("Latvian"),
      "lv-LV": autoTranslate("Latvian"),
      ml: autoTranslate("Malayalam"),
      "ml-IN": autoTranslate("Malayalam"),
      ms: autoTranslate("Malay"),
      "ms-MY": autoTranslate("Malay"),
      nb: autoTranslate("Norwegian"),
      "nb-NO": autoTranslate("Norwegian"),
      nl: autoTranslate("Dutch"),
      "nl-NL": autoTranslate("Dutch"),
      pa: autoTranslate("Punjabi"),
      "pa-IN": autoTranslate("Punjabi (India)"),
      "pa-PK": autoTranslate("Punjabi (Pakistan)"),
      pl: autoTranslate("Polish"),
      "pl-PL": autoTranslate("Polish"),
      pt: autoTranslate("Portuguese"),
      "pt-BR": autoTranslate("Portuguese (Brazil)"),
      "pt-PT": autoTranslate("Portuguese (Portugal)"),
      ro: autoTranslate("Romanian"),
      "ro-RO": autoTranslate("Romanian"),
      ru: autoTranslate("Russian"),
      "ru-RU": autoTranslate("Russian"),
      sk: autoTranslate("Slovakian"),
      "sk-SK": autoTranslate("Slovakian"),
      sl: autoTranslate("Slovenian"),
      "sl-SI": autoTranslate("Slovenian"),
      sr: autoTranslate("Serbian"),
      "sr-SP": autoTranslate("Serbian"),
      sv: autoTranslate("Swedish"),
      "sv-SE": autoTranslate("Swedish"),
      sw: autoTranslate("Swahili"),
      "sw-KE": autoTranslate("Swahili"),
      ta: autoTranslate("Tamil"),
      "ta-IN": autoTranslate("Tamil"),
      te: autoTranslate("Telugu"),
      "te-IN": autoTranslate("Telugu"),
      th: autoTranslate("Thai"),
      "th-TH": autoTranslate("Thai"),
      tr: autoTranslate("Turkish"),
      "tr-TR": autoTranslate("Turkish"),
      uk: autoTranslate("Ukrainian"),
      "uk-UA": autoTranslate("Ukrainian"),
      ur: autoTranslate("Urdu"),
      "ur-PK": autoTranslate("Urdu"),
      vi: autoTranslate("Vietnamese"),
      "vi-VN": autoTranslate("Vietnamese"),
      zh: autoTranslate("Chinese"),
      "zh-CN": autoTranslate("Chinese (Simplified)"),
      "zh-TW": autoTranslate("Chinese (Traditional)"),
      zu: autoTranslate("Zulu"),
      "zu-ZA": autoTranslate("Zulu"),
    }[language] ??
    (language as TranslatedString) ??
    null
  );
}
