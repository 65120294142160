import { getTranslatedLanguageName } from "../../../utils/languages";
import { BodyText, MinutiaeText } from "../../../nessie";
import { GlobeIcon } from "../../../nessie/icons";
import ProfilePicture from "../../misc/ProfilePicture";
import TooltipTrigger from "../../overlay/TooltipTrigger";
import { Receipt } from "./types";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

type FilteredReceiptItemProps = {
  receipt: Receipt;
};

const FilteredReceiptItem = ({ receipt }: FilteredReceiptItemProps): JSX.Element => {
  return (
    <div
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        marginBottom: "dt_m",
        alignItems: "center",
        position: "relative",
      }}
    >
      <ProfilePicture src={receipt.entityAvatarURL} colorKey={receipt.entityId} size="4rem" />
      <div sx={{ display: "flex", flex: 1, flexDirection: "column", marginLeft: "dt_s", position: "relative" }}>
        <BodyText>{receipt.entityName}</BodyText>
        {receipt.entityRelation && <MinutiaeText kind="disabled">{receipt.entityRelation}</MinutiaeText>}
        {receipt.entityType === "teacher" && <MinutiaeText kind="disabled">{autoTranslate("Teacher")}</MinutiaeText>}
      </div>
      {receipt.language && <ReceiptTranslation receipt={receipt} />}
    </div>
  );
};

export default FilteredReceiptItem;

type ReceiptTranslationProps = Pick<FilteredReceiptItemProps, "receipt">;

const ReceiptTranslation = ({ receipt }: ReceiptTranslationProps): JSX.Element | null => {
  const language = receipt.language && getTranslatedLanguageName(receipt.language);
  if (!language) return null;
  const contentText = autoTranslate("Viewed translation in __language__", { language });
  return (
    <div sx={{ marginRight: "dt_l", position: "relative" }}>
      <TooltipTrigger tooltipContent={contentText} tooltipPosition="left" tooltipOffset="50%">
        <GlobeIcon color="dt_content_tertiary" />
      </TooltipTrigger>
    </div>
  );
};
