import * as React from "react";
import { NessieThemeColors } from "./theme";

type CardProps = {
  className?: string;
  backgroundColor?: NessieThemeColors;
  border?: "none" | "dt_card";
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  "aria-label"?: string;
  role?: string;
};

const Card = ({
  className,
  backgroundColor = "dt_background_primary",
  border = "dt_card",
  children,
  "data-name": dataName,
  "aria-label": ariaLabel,
  role,
}: React.PropsWithChildren<CardProps>) => {
  return (
    <div
      className={className}
      sx={{
        backgroundColor,
        boxShadow: "dt_shadow_shadezies",
        border,
        borderRadius: "dt_radius_m",
        overflow: "hidden",
      }}
      data-name={dataName}
      aria-label={ariaLabel}
      role={role}
    >
      {children}
    </div>
  );
};

export { Card };
